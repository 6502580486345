import React, { Fragment } from "react";
import { render } from "react-dom";
import "../styles/main.scss";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
  }
  render() {
    return (
      <Fragment>
        <div className="textPage privacy">
          <Header />
          <section className="textPage__container">
            <h1>Informativa Privacy</h1>
            <div className="textPage__content">
              <p><strong>Conversion E3 S.r.l.,</strong> con sede legale in Viale Vittorio Veneto 22, 20124 - Milano, Codice
              Fiscale/Partita IVA n. 02364010419, (di seguito, il “<strong>Titolare</strong>”), proprietario della <a href="/">landing
    page</a> (di seguito, il “<strong>Sito</strong>”), in qualità di titolare del trattamento dei
    dati personali degli utenti che navigano e che si registrano tramite il Sito (di seguito, gli
    “<strong>Utenti</strong>”), fornisce qui di seguito l’informativa privacy ai sensi dell’art. 13 del Regolamento
    UE 2016/679 del 27 aprile 2016 (di seguito, “<strong>Regolamento</strong>”, il Regolamento è anche definito
    “<strong>Normativa Applicabile</strong>”).</p>
              <p>Questo Sito e i servizi eventualmente offertitramite il Sito sono riservati a soggetti che hanno compiuto il
              diciottesimoanno di età. Il Titolare non raccoglie quindi dati personali relativi aisoggetti minori di anni 18.
              Su richiesta degli Utenti, il Titolare cancelleràtempestivamente tutti i dati personali involontariamente
    raccolti e relativi a soggetti minori di anni 18.</p>
              <p>Il Titolare tiene nella massima considerazione ildiritto alla privacy ed alla protezione dei dati personali dei
              propri Utenti.Per ogni informazione in relazione alla presente informativa privacy, gliUtenti possono contattare
    il Titolare in qualsiasi momento, utilizzando le seguenti modalità:</p>
              <ul>
                <li>Inviando una raccomandata a/r alla sede legale del Titolare: Viale Vittorio Veneto 22, 20124 - Milano;</li>
                <li>Inviando un messaggio di posta elettronica all’indirizzo: <a target="_blank" href="mailto:privacy@gruppodigitouch.it">privacy@gruppodigitouch.it</a>.</li>
              </ul>
              
              
              
              
              <h3><span className="anchor" id="p1"></span>1. Finalità del trattamento</h3>
              <p>I dati personali degli Utenti (ovvero, il nome, il cognome, l’indirizzo e-mail, il numero di telefono e l’azienda) saranno trattati lecitamente dal
              Titolare ai sensidella Normativa Applicabile per consentire al singolo Utente di registrarsi al Sito al fine di
              poter essere ricontattato dal Titolare e ricevere maggioriinformazioni e/o materiali riguardo il prodotto
              promosso dal Titolare tramite icontenuti visionabili all’interno del Sito. Fermo restando quanto previstoaltrove
              in questa informativa privacy, in nessun caso il Titolare renderàaccessibili agli altri Utenti e/o terzi i dati
              personali degli Utenti. Ilconferimento dei dati personali per la finalità di trattamento sopra indicata
              èfacoltativo ma necessario, poiché il mancato conferimento degli stessicomporterà l’impossibilità per l’Utente
    di registrarsi al Sito e poter essere ricontattato da parte del Titolare e/o ricevere il relativo materiale.</p>
              
              
              
              
              <h3><span className="anchor" id="p2"></span>2. Ulteriori finalità di trattamento:ricontatto per finalità di business in linea con le preferenze
        espresse dall’Utente</h3>
              <p>Con il consenso <strong>libero e facoltativo</strong> dell’Utente, il Titolare tratterà alcuni dati
    personali dell’Utente (ovvero, il nome, il cognome, l’indirizzo e-mail, il numero di telefono, l’azienda, l’UserID, le preferenze segnalate
    attraverso la navigazione del Sito) anche per finalità di promozione dei servizi offerti dal Titolare, in linea
    con le preferenze espresse dall’Utente nel corso della navigazione del Sito, ovvero affinché il Titolare possa
    contattare gli Utenti, tramite posta elettronica, per proporre l’acquisto di prodotti e/o servizi offerti dallo
    stesso Titolare o da aziende terze, per presentare promozioni e nuove opportunità commerciali coerenti con il
    profilo dell’Utente individuato.</p>
              <p>In caso di mancato consenso, la possibilità diregistrarsi al Sito e ricevere maggiori informazioni e/o materiali
    di cui al <a href="#p1">paragrafo 1</a> non sarà in alcun modo pregiudicata.</p>
              <p>In caso di prestazione del consenso, lo stessopotrà in qualsiasi momento essere revocato, facendone richiesta al
    Titolare con le modalità indicate al successivo <a href="#p5">paragrafo 5</a>.</p>
              <p>Ci si potrà inoltre opporre facilmente adulteriori invii di comunicazioni via e-mail anche cliccando
              sull’apposito linkper la revoca del consenso, che è presente in ciascuna e-mail promozionale. Effettuata la
              revoca del consenso,il Titolare invierà un messaggio di posta elettronica per confermare l’avvenuta revoca.</p>
              <p>A seguito dell’esercizio del diritto diopposizione all’invio di comunicazioni promozionali via e-mail, è
              possibileche, per ragioni tecniche ed operative (es. formazione delle liste di contattogià completata poco prima
              della ricezione da parte dei Titolari della richiestadi opposizione) si continui a ricevere alcuni ulteriori
              messaggi promozionali.Qualora si dovesse continuare a ricevere messaggi dopo che siano trascorse 24ore
              dall’esercizio del diritto di opposizione, si prega di segnalare il problema ai Titolari, utilizzando i contatti
    indicati al successivo <a href="#p5">paragrafo 5</a>.</p>
              
              
              
              
              <h3><span className="anchor" id="p3"></span>3. Modalità di trattamento e tempi di conservazione dei dati</h3>
              <p>Il Titolare tratterà i dati personali degliUtenti mediante strumenti manuali ed informatici, con logiche
              strettamentecorrelate alle finalità stesse e, comunque, in modo da garantire la sicurezza e la riservatezza dei
    dati stessi.</p>
              <p>I dati personali degli Utenti saranno conservatiper i tempi strettamente necessari a gestire la richiesta di
              ricontatto dallostesso formulata, nonchè recapitare i materiali, nel rispetto della finalitàillustrata al
    precedente <a href="#p1">paragrafo 1</a>.</p>
              <p>I dati personali indicati al <a href="#p2">paragrafo 2</a>, sarannoutilizzati dal Titolare fino ad opposizione o revoca del
              consenso e comunqueper un periodo massimo di 12 (dodici) mesi dall’ultima attività dell’Utente sulSito e/o
              riconducibile ai servizi offerti dal Titolare (è considerata attivitàun accesso al Sito, un’interazione con le
    comunicazioni inviate dal Titolare, l’accesso ai contenuti e ai servizi del Sito etc.).</p>
              <p>Ove necessario, ad esempio in caso di eserciziodel diritto di limitazione esercitato dall’interessato ovvero in
              caso dirichieste da parte delle competenti Autorità o in virtù di un obbligo di legge,i dati personali
              strettamente necessari, saranno trattati per il periodostrettamente necessario a garantire i diritti
    dell’interessato ovvero per adempiere ad un obbligo di legge o un ordine delle Autorità.</p>
              
              
              
              
              <h3><span className="anchor" id="p4"></span>4. Ambito di comunicazione e diffusione dei dati</h3>
              <p>Potranno venire a conoscenza dei dati personalidegli Utenti dipendenti e/o collaboratori del Titolare che sono
              stati istruitiin tal senso dai Titolare ai sensi dell’articolo 29 del Regolamento,tratteranno i dati degli
              Utenti esclusivamente per le finalità indicate nellapresente informativa e nel rispetto delle previsioni della
    Normativa Applicabile.</p>
              <p>Potranno inoltre venire a conoscenza dei datipersonali degli Utenti i soggetti terzi che potranno trattare dati
              personaliper conto del Titolare in qualità di “Responsabili Esterni del Trattamento” aisensi dell’articolo 28
              del Regolamento, quali, a titolo esemplificativo,fornitori di servizi informatici e/o altri servizi necessari
    all’organizzazione e/o alla gestione del Sito.</p>
              <p>Infine, potranno venire a conoscenza dei datipersonali degli Utenti anche le società facenti parte del gruppo di
              società controllateda DigiTouch S.p.A. – tra le quali rientra il Titolare - il cui elenco completo può essere
    consultato all’interno del sito <a href="http://www.gruppodigitouch.it">www.gruppodigitouch.it</a> e che
    agiranno come titolari del trattamento nel caso in cui la trasmissione dei dati sia necessaria all’esecuzione
    dei servizi di volta in volta richiesti dagli Utenti stessi.</p>
              <p>Gli Utenti hanno il diritto di ottenere una listadegli eventuali responsabili del trattamento nominati dal
    Titolare, facendone richiesta al Titolare con le modalità indicate al successivo <a href="#p5">paragrafo 5</a>.</p>
              
              
              
              
              <h3><span className="anchor" id="p5"></span>5. Diritti degli Interessati</h3>
              <p>Gli Utenti potranno esercitare i dirittigarantiti loro dalla Normativa Applicabile, contattando il Titolare con
    le seguenti modalità:</p>
              <ul>
                <li>Inviando una raccomandata a/r alla sede legale del Titolare: Viale Vittorio Veneto 22, 20124 - Milano;</li>
                <li>Inviando un messaggio di posta elettronica all’indirizzo: <a target="_blank" href="mailto:privacy@gruppodigitouch.it">privacy@gruppodigitouch.it</a></li>
              </ul>
              <p>Ai sensi della Normativa Applicabile, il Titolareinforma che gli Utenti hanno il diritto di ottenere
              l’indicazione (i)dell’origine dei dati personali; (ii) delle finalità e modalità deltrattamento; (iii) della
              logica applicata in caso di trattamento effettuato conl’ausilio di strumenti elettronici; (iv) degli estremi
              identificativi del Titolaree dei responsabili; (v) dei soggetti o delle categorie di soggetti ai quali idati
              personali possono essere comunicati o che possono venirne a conoscenza in qualità di responsabili o incaricati.
</p>
              <p>Inoltre, gli Utenti hanno il diritto di ottenere:</p>
              <p>a) l’accesso,l’aggiornamento, la rettificazione ovvero, quando vi hanno interesse, l’integrazione dei dati;</p>
              <p>b) la cancellazione(diritto all’oblio), la trasformazione in forma anonima o la limitazione deidati trattati in
              violazione di legge, compresi quelli di cui non è necessariala conservazione in relazione agli scopi per i quali
    i dati sono stati raccolti o successivamente trattati;</p>
              <p>c) l’attestazione che leoperazioni di cui alle lettere a) e b) sono state portate a conoscenza, ancheper quanto
              riguarda il loro contenuto, di coloro ai quali i dati sono staticomunicati o diffusi, eccettuato il caso in cui
    tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente</p>
              <p>sproporzionato rispetto al diritto tutelato.</p>
              <p>Inoltre, gli Utenti hanno:</p>
              <p>a) il diritto di revocareil consenso in qualsiasi momento, qualora il trattamento si basi sul loro consenso;</p>
              <p>b) (ove applicabile) ildiritto alla portabilità dei dati (diritto di ricevere tutti i dati personaliche li
    riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico);</p>
              <p>c) il diritto di opporsiin tutto o in parte, per motivi legittimi, al trattamento dei dati personali che li
    riguardano, ancorché pertinenti allo scopo della raccolta;</p>
              <p>d) qualora ritenesseroche il trattamento che li riguarda violi il Regolamento, il diritto di proporrereclamo a
              un’Autorità di controllo (nello Stato membro in cui risiedonoabitualmente, in quello in cui lavorano oppure in
    quello in cui si è verificata la presunta violazione).</p>
              <p>L’Autorità di controllo italiana è <strong>il Garante per la protezione dei dati personali</strong>, con sede in
    con sede in Piazza Venezia n. 11, 00187 – Roma (http://www.garanteprivacy.it/).</p>

            </div>
          </section>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
export default Home;